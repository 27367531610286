import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import PalmTree from './Images/Palm-Tree-Sunset.png'
import { Typography } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: "#CFFCFA", // custom primary color
        },
        secondary: {
            main: "#E1D9F7", // custom secondary color
        },
    },
});

const ContactForm = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');



    return (
        <div>
            <div className='pt-10 px-5'><Typography paragraph><h1 className='text-3xl font-bold'>Contact Phuket Shuttle & Taxi Services</h1></Typography></div>

            <div className='lg:flex lg:gap-5'>
                <div className='lg:w-2/3 p-5'>
                    <div><Typography paragraph>Contact us today to book your transfer and enjoy our reliable and efficient taxi services in Phuket.</Typography></div>
                    <div><Typography paragraph>We have experienced English-speaking drivers who will ensure that you have a comfortable and enjoyable ride.</Typography></div>
                    <form autoComplete="off">
                        <div className='bg-white/50 rounded-xl p-5'>
                            <form autoComplete="off">
                                <FormControl fullWidth sx={{ m: 1 }} variant="standard" label="Name" name="name" required value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    type="text">
                                    <InputLabel htmlFor="standard-adornment-amount">Name:</InputLabel>
                                    <Input
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start"><PersonIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </form>

                            <div>
                                <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                    label="Phone"
                                    name="phone"
                                    required
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                    type="tel">
                                    <InputLabel htmlFor="standard-adornment-amount">Phone:</InputLabel>
                                    <Input
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </div>


                            <div>
                                <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                    label="Email"
                                    name="email"
                                    required
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    type="email">
                                    <InputLabel htmlFor="standard-adornment-amount">E-mail:</InputLabel>
                                    <Input
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </div>


                            <div>
                                <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                    label="Message"
                                    name="message"
                                    required
                                    value={message}
                                    onChange={(event) => setMessage(event.target.value)}
                                >
                                    <InputLabel htmlFor="standard-adornment-amount" sx={{ top: '-12px', paddingLeft: '5px', paddingRight: '5px' }}>Message:</InputLabel>
                                    <Input multiline rows={7}
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start" sx={{ position: 'absolute', top: 0 }}><MessageIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </div>

                        </div>




                        <div className='mt-5'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    disabled
                                >
                                    <SendIcon />
                                    &nbsp;&nbsp;
                                    <b>Submit</b>
                                </Button>
                            </ThemeProvider>
                        </div>

                    </form>
                </div>
                <div className='lg:w-1/3'>
                    <div className='float-right clear-both'><img src={PalmTree} alt='' /></div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;