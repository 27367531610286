import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import HailIcon from '@mui/icons-material/Hail';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    // Add any other styles you want to apply to the TableCell here
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(Icon, name, From, To, Pax, PaxOther) {
    return { Icon, name, From, To, Pax, PaxOther };
}

const rows = [
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '159', 'Phuket Town', '600 Thb.', '1000 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '237', 'Rassada Pier (Phuket Town)', '600 Thb.', '1000 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '262', 'Boat Lagoon (Marina)', '550 Thb', '900 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '305', 'Royal Phuket Marina', '550 Thb', '900 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '356', 'Naiyang Beach, Naithorn Beach', '400 Thb.', '900 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '159', 'Layan Beach, Laguna Beach', '500 Thb.', '1000 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '237', 'Bangtao Beach, Surin Beach', '550 Thb.', '1000 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '262', 'Kamala Beach', '550 Thb.', '1000 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '305', 'Patong Beach', '700 Thb.', '1100 Thb'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '356', 'Kata Beach, Karon Beach', '800 Thb.', '1200 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '159', 'Rawai Beach, Yanui, Naiya', '800 Thb.', '1400 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '237', 'Naiharn Beach', '900 Thb.', '1400 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '262', 'Chalong Pier', '700 Thb.', '1200 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '305', 'Cape Panwa', '700 Thb.', '1200 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '356', 'Laem sai, Ao por', '500 Thb.', '900 Thb.'),
    createData(<ConnectingAirportsIcon fontSize='large' />, 'Phuket Airport', '356', 'JW Marriott Hotel', '600 Thb.', '1000 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Khaolak Beach(Phang nga)', '1800 Thb.', '3000 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Tablamu Pier (Phang nga)', '1600 Thb.', '2800 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Phang nga bay, Town', '1600 Thb.', '3000 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Krabi Town, Ao nang, Railey', '2300 Thb.', '4200 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Koh Lanta', '4800 Thb.', '6500 Thb'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Trang Town, Pak meng Pier', '5000 Thb.', '6000 Thb'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Had yai', '5600 Thb.', '7000 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Song kla', '6000 Thb.', '7500 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Padang bhe sar', '6500 Thb.', '8500 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Satoon, Tammalang, Pakpala', '6500 Thb.', '8500 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Nakornsri Dhammarat', '5000 Thb.', '6500 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Surat thani Town, Train Station', '4500 Thb.', '5500 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Surat thani (Donsak Pier)', '5000 Thb.', '6000 Thb.'),
    createData(<HailIcon fontSize='large' />, 'Phuket (anywhere)', '356', 'Ranong', '4500 Thb.', '6500 Thb.'),
];

export default function PhuketTaxiRates() {
    return (
        <div className='p-5'>
            <div className='pt-5'><Typography paragraph><h1 className='text-3xl'>Phuket Taxi, Minibus, Coach Service Rate</h1></Typography></div>


            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow className='bg-[#000000]'>
                            <StyledTableCell align='right'></StyledTableCell>
                            <StyledTableCell colSpan={2} align='right' style={{ color: '#d9f7e1', fontWeight: 'bold' }}>Destination (one way)</StyledTableCell>


                            <StyledTableCell align='right' className='text-[#d9f7e1]' style={{ color: '#d9f7e1', fontWeight: 'bold' }}><LocalTaxiIcon fontSize='large' className='text-[#d9f7e1]' /> Taxi</StyledTableCell>
                            <StyledTableCell align='right' className='text-[#d9f7e1]' style={{ color: '#d9f7e1', fontWeight: 'bold' }}><DirectionsBusIcon fontSize='large' className='text-[#d9f7e1]' /> Minibus</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell component="th" scope="row" align='right' style={{ backgroundColor: '#000000' }}>

                            </StyledTableCell>
                            <StyledTableCell align='right'>From</StyledTableCell>
                            <StyledTableCell align='right'>To</StyledTableCell>
                            <StyledTableCell align='right'>1-3 Pax</StyledTableCell>
                            <StyledTableCell align='right'>4-12 Pax</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" align='right' style={{ backgroundColor: '#000000', color: '#d9f7e1' }}>
                                    {row.Icon}
                                </StyledTableCell>
                                <StyledTableCell align='right'>{row.name}</StyledTableCell>
                                <StyledTableCell align='right'>{row.To}</StyledTableCell>
                                <StyledTableCell align='right'>{row.Pax}</StyledTableCell>
                                <StyledTableCell align='right'>{row.PaxOther}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
