import React from 'react'
import { Button, Typography } from '@mui/material';
import Thailand from './Images/Thailand.png'
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#CFFCFA", // custom primary color
        },
        secondary: {
            main: "#E1D9F7", // custom secondary color
        },
    },
});

export default function Home() {
    return (
        <div className='p-5'>

            <div className='pt-5'><Typography paragraph><h1 className='text-3xl font-bold'>Welcome to Supakorn Pronpuriwat Phuket Shuttle & Taxi Services</h1></Typography></div>
            <div className='float-right ml-7 mb-7'><img src={Thailand} alt='' /></div>
            <div><Typography paragraph>Your go-to solution for all your transportation needs in Phuket and nearby areas. Our team provides reliable, safe, and comfortable taxi and transfer services to ensure that you reach your destination quickly and efficiently.</Typography></div>
            <div><Typography paragraph>Our Phuket taxi service is perfect for those who want to avoid the hassle of waiting in line for a taxi or dealing with tour booking agencies. With our service, you can be sure that your driver will be waiting for you at the airport exit, holding a sign with your name on it. You can enjoy a stress-free transfer experience without any interruptions en route to your destination. We operate all transfers on a private basis, ensuring that you receive personalized attention and comfort during your journey.</Typography></div>
            <div><Typography paragraph>We offer competitive prices per vehicle, not per person, making our services affordable for individuals, families, and groups. You can book your transfer online by filling out the details below and selecting your preferred destination. We also provide baby seats upon request to ensure your child's safety and comfort during the journey.</Typography></div>
            <div><Typography paragraph>Our transfer services cover a wide range of destinations, including Phuket, Phi Phi, Khao Lak, Krabi, and Lanta. We have experienced English-speaking drivers who will ensure that you have a comfortable and enjoyable ride. With payment on arrival, you can be sure that you won't have to deal with any payment issues during the journey.</Typography></div>
            <div><Typography paragraph>At Supakorn Pronpuriwat Phuket Shuttle & Taxi Services, we strive to provide our clients with the best possible transportation experience. Our team is dedicated to ensuring that you arrive at your destination on time, safely, and comfortably. Contact us today to book your transfer and enjoy our reliable and efficient taxi services in Phuket.</Typography></div>
            <div><ThemeProvider theme={theme}><Button variant='contained' color='secondary' fullWidth>Call Now</Button></ThemeProvider></div>

        </div>
    )
}
