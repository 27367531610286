import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Box,
    Typography,
    Link,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .MuiDrawer-paper": {
        backgroundColor: "#B8D1BE",
        //position: "relative",
        //overflow: "hidden",
        "&::before": {
            content: '""',
            // display: "block",
            //position: "absolute",
            top: 0,
            left: 0,
            width: 240,
            backgroundColor: '#B8D1BE',
            zIndex: -1,
        },
    },
}));

const StyledList = styled(List)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
        backgroundColor: '#B8D1BE',
    },
}));

const Navigation = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className='bg-[#B8D1BE]'>

            <StyledList className='bg-[#B8D1BE]'>

                <Box className='lg:flex clear-both justify-center'>

                    <ListItem>
                        <ListItemIcon>
                            <Link href='/' exact style={{ textDecoration: 'none', color: 'black' }}><Typography><h2 className='text-xl font-bold'>Phuket Taxi Services</h2></Typography></Link>
                        </ListItemIcon>

                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link href='/' style={{ textDecoration: 'none', color: 'black' }} exact><HomeIcon />Home</Link>
                        </ListItemIcon>

                    </ListItem>
                    <ListItem Button to="/PhuketTaxiRates">

                        <ListItemIcon>
                            <Link href='/PhuketTaxiRates' style={{ textDecoration: 'none', color: 'black' }} exact><DriveEtaIcon /> Phuket Taxi Rates</Link>
                        </ListItemIcon>

                    </ListItem>

                    <ListItem Button to="/PhuketTaxiRates">

                        <ListItemIcon>
                            <Link href='/PhuketTaxiReservation' style={{ textDecoration: 'none', color: 'black' }} exact><DriveEtaIcon /> Phuket Taxi Reservation</Link>
                        </ListItemIcon>

                    </ListItem>

                    <ListItem Button to="/Contact">

                        <ListItemIcon>
                            <Link href='/Contact' style={{ textDecoration: 'none', color: 'black' }} exact><ContactMailIcon /> Contact</Link>
                        </ListItemIcon>

                    </ListItem>


                </Box>
            </StyledList>
        </div>
    );

    return (
        <>
            <Box sx={{ display: { md: 'none' } }}>
                <IconButton onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
            </Box>
            <StyledDrawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                {drawer}
            </StyledDrawer>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {drawer}
            </Box>
        </>
    );
};

export default Navigation;