import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import PaymentsIcon from '@mui/icons-material/Payments';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, Input, InputAdornment, MenuItem, Select } from '@mui/material';
import { Typography } from '@mui/material';
import PakNam from './Images/Pak-Nam.png'


const theme = createTheme({
    palette: {
        primary: {
            main: "#CFFCFA", // custom primary color
        },
        secondary: {
            main: "#E1D9F7", // custom secondary color
        },
    },
});

const PhuketTaxiReservation = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [pickupHour, setPickupHour] = useState('');
    const [pickupArea, setPickupArea] = useState('');
    const [pickupAddress, setPickupAddress] = useState('');
    const [destinationArea, setDestinationArea] = useState('');
    const [destinationAddress, setDestinationAddress] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePickupDateChange = (event) => {
        setPickupDate(event.target.value);
    };

    const handlePickupHourChange = (event) => {
        setPickupHour(event.target.value);
    };

    const handlePickupAreaChange = (event) => {
        setPickupArea(event.target.value);
    };

    const handlePickupAddressChange = (event) => {
        setPickupAddress(event.target.value);
    };

    const handleDestinationAreaChange = (event) => {
        setDestinationArea(event.target.value);
    };

    const handleDestinationAddressChange = (event) => {
        setDestinationAddress(event.target.value);
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };
    return (
        <div>
            <div className='pt-10 px-5'><Typography paragraph><h1 className='text-3xl font-bold'>Phuket Taxi Reservation</h1></Typography></div>

            <div className='lg:flex lg:gap-5'>

                <div className='lg:w-2/3 p-5'>

                    <div className='bg-white/50 rounded-xl p-5'>
                        <form autoComplete="on">
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="name">Name</InputLabel>
                                <Input
                                    id="name"
                                    startAdornment={<InputAdornment position="start"><PersonIcon /></InputAdornment>}
                                    value={name}
                                    onChange={handleNameChange}
                                    type="text"
                                    required
                                />
                            </FormControl>
                        </form>

                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="phone">Phone</InputLabel>
                                <Input
                                    id="phone"
                                    startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    type="tel"
                                    required
                                />
                            </FormControl>
                        </div>

                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input
                                    id="email"
                                    startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                                    value={email}
                                    onChange={handleEmailChange}
                                    type="email"
                                    required
                                />
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="pickup-date">Pickup Date</InputLabel>
                                <Input
                                    id="pickup-date"
                                    startAdornment={<InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>}

                                    value={pickupDate}
                                    onChange={handlePickupDateChange}
                                    type="date"
                                    required
                                    fullWidth
                                />
                            </FormControl>
                        </div>

                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="pickup-hour">Pickup Hour</InputLabel>
                                <Input
                                    id="pickup-hour"
                                    startAdornment={<InputAdornment position="start"><AccessTimeIcon /></InputAdornment>}
                                    value={pickupHour}
                                    onChange={handlePickupHourChange}
                                    type="time"
                                    required
                                />
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="pickup-area">Pickup Area</InputLabel>
                                <Select
                                    id="pickup-area"
                                    startAdornment={<InputAdornment position="start"><LocationOnIcon /></InputAdornment>}
                                    value={pickupArea}
                                    onChange={handlePickupAreaChange}
                                    required
                                >

                                    <MenuItem value="PhuketAirport">Phuket Airport</MenuItem>
                                    <MenuItem value="Phuket(anywhere)">Phuket (anywhere)</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="pickup-address">Pickup Address</InputLabel>
                                <Input
                                    id="pickup-address"
                                    startAdornment={<InputAdornment position="start"><LocationOnIcon /></InputAdornment>}
                                    value={pickupAddress}
                                    onChange={handlePickupAddressChange}
                                    type="text"
                                    required
                                />
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="destination-area">Destination Area</InputLabel>
                                <Select
                                    id="destination-area"
                                    startAdornment={<InputAdornment position="start"><HomeIcon /></InputAdornment>}
                                    value={destinationArea}
                                    onChange={handleDestinationAreaChange}
                                    required
                                >

                                    <MenuItem value="PhuketTown">Phuket Town</MenuItem>
                                    <MenuItem value="RassadaPier(PhuketTown)">Rassada Pier (Phuket Town)</MenuItem>
                                    <MenuItem value="BoatLagoon(Marina)">Boat Lagoon (Marina)</MenuItem>
                                    <MenuItem value="RoyalPhuketMarina">Royal Phuket Marina</MenuItem>
                                    <MenuItem value="NaiyangBeachNaithornBeach">Naiyang Beach, Naithorn Beach</MenuItem>
                                    <MenuItem value="LayanBeachLagunaBeach">Layan Beach, Laguna Beach</MenuItem>
                                    <MenuItem value="BangtaoBeachSurinBeach">Bangtao Beach, Surin Beach</MenuItem>
                                    <MenuItem value="KamalaBeach">Kamala Beach</MenuItem>
                                    <MenuItem value="PatongBeach">Patong Beach</MenuItem>
                                    <MenuItem value="KataBeachKaronBeach">Kata Beach, Karon Beach</MenuItem>
                                    <MenuItem value="RawaiBeachYanuiNaiya">Rawai Beach, Yanui, Naiya</MenuItem>
                                    <MenuItem value="NaiharnBeach">Naiharn Beach</MenuItem>
                                    <MenuItem value="ChalongPier">Chalong Pier</MenuItem>
                                    <MenuItem value="CapePanwa">Cape Panwa</MenuItem>
                                    <MenuItem value="LaemsaiAopor">Laem sai, Ao por</MenuItem>
                                    <MenuItem value="JWMarriottHotel">JW Marriott Hotel</MenuItem>
                                    <MenuItem value="KhaolakBeach(Phangnga)">Khaolak Beach(Phang nga)</MenuItem>
                                    <MenuItem value="TablamuPier(Phangnga)">Tablamu Pier (Phang nga)</MenuItem>
                                    <MenuItem value="PhangngabayTown">Phang nga bay, Town</MenuItem>
                                    <MenuItem value="KrabiTownAonangRailey">Krabi Town, Ao nang, Railey</MenuItem>
                                    <MenuItem value="KohLanta">Koh Lanta</MenuItem>
                                    <MenuItem value="TrangTownPakmengPier">Trang Town, Pak meng Pier</MenuItem>
                                    <MenuItem value="Hadyai">Had yai</MenuItem>
                                    <MenuItem value="Songkla">Song kla</MenuItem>
                                    <MenuItem value="SatoonTammalangPakpala">Satoon, Tammalang, Pakpala</MenuItem>
                                    <MenuItem value="NakornsriDhammarat">Nakornsri Dhammarat</MenuItem>
                                    <MenuItem value="SuratthaniTownTrainStation">Surat thani Town, Train Station</MenuItem>
                                    <MenuItem value="Suratthani(DonsakPier)">Surat thani (Donsak Pier)</MenuItem>
                                    <MenuItem value="Ranong">Ranong</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="destination-address">Destination Address</InputLabel>
                                <Input
                                    id="destination-address"
                                    startAdornment={<InputAdornment position="start"><HomeIcon /></InputAdornment>}
                                    value={destinationAddress}
                                    onChange={handleDestinationAddressChange}
                                    type="text"
                                    required
                                />
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="payment-method">Payment Method</InputLabel>
                                <Select
                                    id="payment-method"
                                    startAdornment={<InputAdornment position="start"><PaymentsIcon /></InputAdornment>}
                                    value={paymentMethod}
                                    onChange={handlePaymentMethodChange}
                                    required
                                >
                                    <MenuItem value="credit-card">In Cab Cash or Credit</MenuItem>

                                </Select>
                            </FormControl>
                        </div>

                        <div className='mt-5'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    disabled
                                >
                                    <SendIcon />
                                    &nbsp;&nbsp;
                                    <b>Submit</b>
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>

                </div>
                <div className='lg:w-1/3'>
                    <div className='lg:float-right lg:mt-14'>
                        <img src={PakNam} alt='' className='mx-auto' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PhuketTaxiReservation;