import { Typography, Link } from '@mui/material'

import React from 'react';
import { CompanyName } from './CompanyName';

const today = new Date();

export default function Footer() {
    return (
        <div>

            <div className='pt-5'><Typography variant="button" ><Link to='/Home' title='Design Company home/landing page' className=' text-black' exact="true" style={{ textDecoration: 'none', color: '#000000' }}>&copy; {today.getFullYear()} - <CompanyName /></Link></Typography></div>

        </div>
    )
}
