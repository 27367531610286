import React from 'react';


export const CompanyName = () => {
    const CompanyName = 'Supakorn Pronpuriwat Phuket Shuttle & Taxi Services'
    return (

        <span aria-label="" title='Supakorn Pronpuriwat Phuket Shuttle & Taxi Services'>{CompanyName}</span>

    )
}
