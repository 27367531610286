import { React, useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from './Home';
import Footer from './Component/Footer';
import Developed from './Component/Developed';
import Divider from '@mui/material/Divider';
import PhuketTaxiRates from './PhuketTaxiRates';
import ContactForm from './Contact';
import PhuketTaxiReservation from './PhuketTaxiReservation';
import Navigation from './Component/Navigation';

const history = createBrowserHistory();


function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className='bg-[#d9f7e1]'>
      <BrowserRouter history={history}>
        <Navigation />
        <div className='mx-auto'>
          <Switch>
            <Route exact="true" path="/" component={Home} />
            <Route exact="true" path="/Home" component={Home} />
            <Route exact="true" path="/PhuketTaxiRates" component={PhuketTaxiRates} />
            <Route exact="true" path="/Contact" component={ContactForm} />
            <Route exact="true" path="/PhuketTaxiReservation" component={PhuketTaxiReservation} />



          </Switch>
        </div>
      </BrowserRouter>

      <div className='mx-auto pb-10 text-center'>
        <Divider />
        <div><Footer /></div>
        <div><Developed /></div>
      </div>

    </div>
  );
}

export default App;
